body {
  font-family: "Helvetica", "Arial", sans-serif;
  background: black;
  //background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 80%, rgba(220,140,0,1) 100%);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
html {
  height: 100%;
}
.h1,
h1 {
  font-size: 1.5rem;
}
.h2,
h2 {
  font-size: 1.25rem;
}
.h3,
h3 {
  font-size: 1.15rem;
}

.mat-focused .placeholder {
  color: #00d318;
}
a {
  color: #fff9c4;
  text-decoration: underline;
  &:hover {
    color: white;
  }
}
