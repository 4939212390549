.logo {
  text-align: center;
  img {
    max-width: 200px;
    margin: 1rem 1rem;
  }
}

.introduccion {
  padding-top: 4rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2rem;
  font-size: 0.8rem;
  p {
    text-align: justify;
  }
}
